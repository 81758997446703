import mainjson from '../config/main';
import { Level } from '../utils/LoginFunctions';

function checkIf(data) {
    if (parseInt(Level()) <= 0) {
        return true;
    }
    else {
        return false;
    }
}

export default function GetData() {
        return {
        "apitype":"shows",
        "id":"show_id",
        "subidname":"show_name",
        "options":["edit","insert","delete"],
        "extraoptions":[
            {
                "name":"Orders",
                "page":"/orders",
            },
            {
                "name":"Dates",
                "page":"/dates",
            }
        ],
        "nameSingle":"event",
        "nameMultiple":"events",

        "filters":
            [
                {
                    "key":"state",
                    "name":"State",
                    "options":[
                        {key:"1",value:"Active"},
                        {key:"0",value:"Inactive"},
                        {key:"2",value:"Unlisted"},
                    ]
                }
            ]
        ,

        "fields":{
            "field0":{
                "name":"Id",
                "field":"show_id",
                "type":"TextInput",
                "edit":false,
                "list":true,
            },
            "field1":{
                "name":"Name",
                "field":"show_name",
                "type":"TextInput",
                "required":true,
                "list":true,
            },
            "field2":{
                "name":"Description",
                "field":"show_description",
                "type":"TextArea",
                "required":true,
                "list":false,
            },
            "field3":{
                "name":"Slug in URL",
                "field":"show_slug",
                "type":"TextInput",
                "required":false,
                "list":false,
            },
            "field4":{
                "name":"Image",
                "field":"show_image",
                "type":"ImageUpload",
                "required":true,
                "list":true,
                "resize":{
                    width:1024,
                    type:"fit",
                    quality:0.85,
                    bgcolor:"#ffffff"
                },
            },
            "field5":{
                "name":"State",
                "field":"show_active",
                "type":"DropDown",
                "options": {
                    "1":"Active",
                    "0":"Inactive",
                    "2":"Unlisted",
                },
                "edit":true,
                "list":true,
            }, 
            "field6":{
                "name":"Location",
                "field":"show_location_id",
                "type":"DropDown",
                "options": "locations",
                "list":true,
            }, 
            "field7":{
                "name":"Sold",
                "field":"sold",
                "type":"Display",
                "list":true,
                "edit":false,   
            }, 
            "field100":{
                "name":"Client",
                "field":"show_client_id",
                "type":"DropDown",
                "options": "usergroups",
                "list":false,
                "if":checkIf
            }, 
        }
    }
}